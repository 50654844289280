<template>
  <div class="row mt_dec--30">
    <!-- Start Single Service  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      class="mt--30"
      v-for="(service, i) in serviceContent"
      :key="i"
    >
      <div class="single-service service__style--2 bg-color-gray">
        <router-link to="#">
          <div class="service">
            <div class="icon">
              <div v-html="iconSvg(service.icon)"></div>
            </div>
            <div class="content">
              <h3 class="heading-title">{{ service.title }}</h3>
              <p>
                {{ service.desc }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </div>
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
  return {
    serviceContent: [
      {
        icon: "globe",
        title: "Website Development",
        desc: `Building responsive and scalable websites using modern technologies such as HTML5, CSS3, JavaScript, and frameworks like Vue.js or React to deliver a seamless user experience across all devices.`,
      },
      {
        icon: "smartphone",
        title: "Mobile Development",
        desc: `Creating high-performance mobile applications tailored to both iOS and Android platforms using tools like Flutter and React Native to ensure cross-platform compatibility and optimal user engagement.`,
      },
      {
        icon: "monitor",
        title: "Desktop Development",
        desc: `Developing robust desktop applications with a focus on functionality and performance using Electron or other desktop development frameworks to meet various business needs.`,
      },
      {
        icon: "hard-drive",
        title: "Data Scraping",
        desc: `Extracting valuable data from websites and platforms efficiently through custom data scraping solutions, ensuring accurate data collection for market research, analytics, or automation purposes.`,
      },
      {
        icon: "users",
        title: "Support Service",
        desc: `Providing dedicated post-launch support and maintenance services, ensuring smooth operation, timely updates, and prompt troubleshooting to keep your project running seamlessly.`,
      },
      {
        icon: "database",
        title: "Clean Code",
        desc: `Delivering clean, maintainable, and well-structured code, following industry best practices to ensure scalability, performance, and easy future development.`,
      },
      {
        icon: "layers",
        title: "Full-Stack Development",
        desc: `Offering comprehensive full-stack development services, from front-end interfaces to back-end architecture, utilizing technologies like Node.js, Laravel, MongoDB, and SQL to build efficient, secure, and dynamic web applications.`,
      },
    ],
  };
},
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
