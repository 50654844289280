<template>
  <v-col lg="12">
    <div class="tab-default">
      <v-tabs v-model="tab" flat hide-slider>
        <v-tab v-for="item in items" :key="item.id" :ripple="false">
          {{ item.name }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
          transition="fade-transition"
          v-for="item in tabContent"
          :key="item.id"
        >
          <v-card flat>
            <v-card-text>
              <div class="single-tab-content">
                <ul>
                  <li v-for="(item, i) in item.content" :key="i">
                    <router-link to="#"
                      >{{ item.title }}
                      <span> - {{ item.name }}</span></router-link
                    >
                    {{ item.desc }}
                  </li>
                </ul>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-col>
</template>
<script>
export default {
  data() {
    return {
      tab: null,
      items: [
        {
          id: 1,
          name: "Main skills",
        },
        // {
        //   id: 2,
        //   name: "Award",
        // },
        {
          id: 3,
          name: "Experience",
        },
        {
          id: 4,
          name: "Education & Certification",
        },
      ],

      tabContent: [
        {
          id: 1,
          content: [
            {
              title: "Database",
              name: "MYSQL, MongoDB, PostgreSQL, SQLserver.",
              desc: "",
            },
            {
              title: "Programming language",
              name: "PHP, Python, Javascript, Pascal, Delphi.",
              desc: "",
            },
            {
              title: "Framework",
              name: "Codeigniter, Laravel, Vue JS, Flutter, React JS, Django, Express js,React Native.",
              desc: "",
            },
            {
              title: "Runtime environment",
              name: "Node.js, Docker.",
              desc: "",
            },
          ],
        },
        // {
        //   id: 2,
        //   content: [
        //     {
        //       title: "Awwwards.com",
        //       name: "Winner",
        //       desc: " 2019 - 2020",
        //     },
        //     {
        //       title: "CSS Design Awards",
        //       name: "Winner",
        //       desc: "2017 - 2018",
        //     },
        //     {
        //       title: "Design nominees",
        //       name: "site of the day",
        //       desc: "2013 - 2014",
        //     },
        //   ],
        // },
        {
          id: 3,
          content: [
            {
              title: "Software Engineer",
              name: "PT.SETIA BUSANATEX",
              desc: "2019 - 2022",
            },
            {
              title: "Fullstack Developer",
              name: "PT.SYNERGY CHEMICALS INDONESIA",
              desc: "2020 - 2023",
            },
            {
              title: "Fullstack Developer",
              name: "PT.UNICO TRACTORS INDONESIA",
              desc: "2021 - 2023",
            },
            {
              title: "Founder",
              name: "NUSATECH INNOVATIVE SOLUTIONS",
              desc: "2024",
            },
          ],
        },
        {
          id: 4,
          content: [
            // {
            //   title: "Bachelor In Computer Science",
            //   name: "STMIK AMIK BANDUNG",
            //   desc: "2021-Current",
            // },
            {
              title: "Diploma in Computer Science",
              name: "STMIK AMIK BANDUNG",
              desc: "2016-2018",
            },
          ],
        },
      ],
    };
  },
};
</script>
